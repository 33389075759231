.upload__file-info {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.attach-button {
  margin-right: 1rem;
}

/* new component */
.file-card-outer-container {
  display: flex;
  flex-direction: row;
}

.file-card-container {
  display: flex;
  width: calc(150% - 30px);
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
  padding: 0.1rem;
}
.file-card-icon {
  color: white;
  padding: 0.3rem;
  border-radius: 5px;
  background: #1578e1;
}

.file-card-info {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.file-name {
  /* width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-size {
  margin-bottom: -1.8rem;
}

.file-card-close {
  color: white;
  background: red;
  border-radius: 5rem;
  position: relative;
  top: 0;
  right: 6px;
  width: 12%;
  height: 38%;
  margin-top: -0.3rem;
  margin-right: -1rem;
}

.file-card-close:hover {
  cursor: pointer;
}
