.chat-container {
  color: white;
}
.d-block {
  color: white;
  font-size: 14px;
}

.bg-dark-card {
  background: #131c31;
  cursor: pointer;
  width: 22%;
  min-width: 22%;
  max-width: 22%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-darks {
  background-color: #262626;
}

.bg-dark-card:hover {
  background-color: #242e47;
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */

  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  font-family: "Inter";
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.typewriter::after {
  content: "\n";
  /* This represents a line break */
  white-space: pre;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.typing-container {
  background: #272525;
  padding: 22px;
  border-radius: 7px;
  margin-top: 52px;
}

.swal2-popup {
  font-size: 10px !important;
  font-family: "Red Hat Display", sans-serif;
  height: max-content !important;
  width: max-content !important;
}

.chat-input-container {
  max-width: 600px;
  margin: 50px auto;
  background-color: #272525;
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  background-color: #272525;
  color: white;
}

.form-control:focus {
  background-color: #272525;
  color: white;
  border: none;
  outline: none;
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input-group {
  align-items: center;
}

.input-group-text {
  background-color: transparent;
  border: none;
  padding-right: 0;
}

.send-btn {
  background-color: #6f42c1;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.send-btn:focus {
  outline: none;
  box-shadow: none;
}

.send-icon {
  width: 24px;
  height: 24px;
}

legend {
  font-size: 1.2em;
  font-weight: bold;
  color: #3498db;
  display: none;
  /* Hide legend for a cleaner appearance */
}

.attach-button,
.search-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.file-info span {
  color: black;
}

/* .file-info {
  text-align: left;
} */

/* .loader {
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
} */

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  text-align: center;
}

.task-list {
  list-style: none;
  padding: 0;

  display: inline-block;
  margin-top: 12rem;
}

.task-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4caf50;
}

/* .loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #6f8cda;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  animation: spin 1s linear infinite;
} */

.sec-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6f8cda;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 49px;
  animation: spin 1s linear infinite;
}

#modal-content1::-webkit-scrollbar {
  display: none;
}
#modal-content1 {
  overflow: scroll;
  height: auto;
}

/* circle loader */

/* .circle-loader {
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #fff;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  margin-left: 39px;
} */

.alert-content.show {
  display: block !important;
}
.alert-title {
  margin-top: -2px;
  margin-left: 9px;
}

.toggle-content {
  background: grey;
  padding: 1px;
  border-radius: 3px;
  font-size: 11px;
  width: 32px;
  text-align: center;

  cursor: pointer;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #fff;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 2.5em;
  width: 0.75rem;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  left: 0.75em;
  top: 2.2em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.75em;
    opacity: 1;
  }
  40% {
    height: 2.5em;
    width: 0.75rem;
    opacity: 1;
  }
  100% {
    height: 2.5em;
    width: 0.75rem;
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.task-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.tick {
  display: none;
  font-size: 18px;
  color: #4caf50;
  margin-right: 10px;
}

.progress-container {
  margin-top: 20px;
}

.head {
  margin-left: 1%;
  /* margin-top: 20%; */
}

.doc-btn {
  position: relative;
  margin: 0;
  padding: 0.8em 1em;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 26px !important;
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.insert-container {
  margin-top: 26.5rem;
}

.chat-containers {
  /* max-width: 95%; */
  margin: 20px auto;
  overflow-y: auto;
  background-color: #1b1b1b;
  height: 90vh;

  /* max-height: 310px;
overflow-y: scroll;
scroll-behavior: smooth; */
}

.chat-containers::-webkit-scrollbar {
  display: none;
}

/* @keyframes scroll{
        0%{
            transform: translateY(0);
        }
        100%{
            transform: translateY(-100%);
        }
    }
    .chat-containers > * {
        display:block;
        animation: scroll 5s linear infinite;
    } */

.user-chat {
  text-align: right;
  margin-bottom: 10px;
}

.download-icon {
  position: absolute;
  top: 2.5rem;
  font-size: 16px;
}

.main-chat {
  text-align: left;
  margin-bottom: 50px;
}

.chat-bubble {
  display: inline-block;
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  position: relative;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
}

.tail {
  background: #262525;
  margin-left: 64px;
  width: 44rem;
  padding: 11px;
  border-radius: 9px;
  height: auto;
  margin-bottom: 13px;
  font-size: 12px;
}

.typing-effect {
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  /* Allows text to wrap to the next line */
  /* border-right: .15em solid orange; Cursor */
  animation: typing 5s steps(40, end), blink-caret 0.75s step-end infinite;
  max-width: 100%;
  /* Sets the maximum width to prevent overflow */
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

/* HTML: <div class="loader"></div> */
.dots {
  width: 12px;
  height: 12px;
  color: #525252;
  background: conic-gradient(
      from -45deg at top 20px left 50%,
      #0000,
      currentColor 1deg 90deg,
      #0000 91deg
    ),
    conic-gradient(
      from 45deg at right 20px top 50%,
      #0000,
      currentColor 1deg 90deg,
      #0000 91deg
    ),
    conic-gradient(
      from 135deg at bottom 20px left 50%,
      #0000,
      currentColor 1deg 90deg,
      #0000 91deg
    ),
    conic-gradient(
      from -135deg at left 20px top 50%,
      #0000,
      currentColor 1deg 90deg,
      #0000 91deg
    );
  animation: l4 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes l4 {
  50% {
    width: 60px;
    height: 60px;
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* HTML: <div class="loader"></div> */
.chat-loader {
  width: 14px;
  height: 14px;
  --c: no-repeat linear-gradient(#525252 0 0);
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 21px 21px;
  animation: chatani 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes chatani {
  0% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }

  33% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    width: 60px;
    height: 60px;
  }

  66% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    width: 60px;
    height: 60px;
  }

  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

.doc-btn:hover {
  background-color: #555;
  /* Change background color on hover */
  color: #eee;
  /* Change text color on hover */
  transform: scale(1.05);
  /* Slightly increase the size of the button */
}

.fa-circle {
  padding-right: 9px;
  font-size: 7px;
}

.scroll-bg {
  background-color: #262525;
}

.folder-view {
  padding: 23px;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.swal2-popup {
  width: max-content !important;
  background-color: #2c2b2b;
  position: absolute;
  left: 315px;
  top: 46px;
  color: white;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #000;
  color: #fff;
  font-size: 8px !important;
  height: 20px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 8px !important;
  height: 20px;
}

.swal2-confirm {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 11px !important;
}

.swal2-actions {
  margin-top: 0px !important;
}

.three-dots {
  width: 9px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin-left: 24px;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #808080, -20px 0 #808080;
    background: #808080;
  }

  33% {
    box-shadow: 20px 0 #808080, -20px 0 #d3d3d3;
    background: #d3d3d3;
  }

  66% {
    box-shadow: 20px 0 #d3d3d3, -20px 0 #808080;
    background: #d3d3d3;
  }

  100% {
    box-shadow: 20px 0 #d3d3d3, -20px 0 #808080;
    background: #808080;
  }
}

.doc-three-dots {
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: doc 1s infinite linear alternate;
  margin-left: 41px;
}

@keyframes doc {
  0% {
    box-shadow: 10px 0 #008000, -10px 0 #008000;
    background: #008000;
  }

  33% {
    box-shadow: 10px 0 #008000, -10px 0 #90ee90;
    background: #90ee90;
  }

  66% {
    box-shadow: 10px 0 #90ee90, -10px 0 #008000;
    background: #90ee90;
  }

  100% {
    box-shadow: 10px 0 #90ee90, -10px 0 #008000;
    background: #008000;
  }
}

.input {
  font-weight: 500;
  font-size: 0.8vw;
  color: #fff;
  background-color: rgb(28, 28, 30);
  box-shadow: 0 0 0.4vw rgba(0, 0, 0, 0.5), 0 0 0 0.15vw transparent;
  border-radius: 34px;
  border: none;
  outline: none;
  padding: 0.4vw;
  max-width: 85%;
  transition: 0.4s;
  margin-left: -7px;
}

.input:hover {
  box-shadow: 0 0 0 0.15vw rgba(135, 207, 235, 0.186);
}

.input:focus {
  box-shadow: 0 0 0 0.15vw #3498db;
}

.input::placeholder {
  color: #3498db;
}

.user-tail {
  background: #2d8fe4;
  width: 100%;
  padding: 12px;
  height: auto;
  border-radius: 12px;
  position: relative;
  left: 143px;
  text-align: left;
}
.question {
  cursor: pointer;
}
.invisible {
  position: absolute;
  left: -9999px;
}

.pdf-download {
  max-width: 108px;
  margin: 87px auto;
  border: none;
  position: fixed;
  right: 38px;
  bottom: 20px;
  z-index: 564;
  background-color: #1578e1;
  padding: 12px;
  border-radius: 49px;
  cursor: pointer;
}

.ppt-download {
  max-width: 108px;
  margin: 137px auto;
  border: none;
  position: fixed;
  right: 38px;
  bottom: 20px;
  z-index: 564;
  background-color: #e17815;
  padding: 12px;
  border-radius: 49px;
  cursor: pointer;
}

.excel-download {
  max-width: 108px;
  margin: 32px auto;
  border: none;
  position: fixed;
  right: 36px;
  bottom: 20px;
  z-index: 564;
  background-color: #2cb722;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  width: 3.5%;
  height: 10%;
}
.view-pdf {
  max-width: 47px;
  margin: 139px auto;

  border: none;
  position: fixed;
  right: 0;
  left: 73rem;
  bottom: 73px;
  z-index: 564;
  background-color: #1578e1;
  padding: 12px;
  border-radius: 49px;
  cursor: pointer;
}

.sidebar {
  width: 44%;
  height: 83%;
  background-color: #252526;
  position: fixed;
  top: 0;
  right: -250px;
  transition: right 0.5s;
}
.modal-content {
  background-color: #252526;
  color: #fff;
}

.modal-header {
  background-color: #252526;
  color: #fff;
  border-bottom: 1px solid #252526;
  padding: 4px 12px;
}
.modal-header .btn-close {
  /* color: #fff !important;
     */
  filter: invert(100%);
}

#floating-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #3d78d0;
  position: fixed;
  bottom: 83px;
  right: 43px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.plus {
  color: white;
  position: absolute;
  top: -10px;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 30px;
  font-family: "Roboto";
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
}

#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 30px;
  right: 30px;
  z-index: 50px;
}

#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px;
}

#container-floating:hover .plus {
  animation: plus-in 0.15s linear;
  animation-fill-mode: forwards;
}

.edit {
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  padding: 0;
  opacity: 0;
  margin: auto;
  line-height: 65px;
  transform: rotateZ(-70deg);
  transition: all 0.3s;
  animation: edit-out 0.3s;
}

#container-floating:hover .edit {
  animation: edit-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
}

@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(180deg);
  }
}

@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.nds {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 300;
  transform: scale(0);
  cursor: pointer;
}

.nd1 {
  background: #d3a411;
  right: 40px;
  bottom: 126px;
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards;
}

.nd3 {
  background: #3c80f6;
  right: 40px;
  bottom: 180px;
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards;
}

.nd4 {
  background: #c2672a;
  right: 40px;
  bottom: 240px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

.nd5 {
  background: #ba68c8;
  right: 40px;
  bottom: 300px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

@keyframes bounce-nds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

#container-floating:hover .nds {
  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards;
}

#container-floating:hover .nd3 {
  animation-delay: 0.08s;
}
#container-floating:hover .nd4 {
  animation-delay: 0.15s;
}
#container-floating:hover .nd5 {
  animation-delay: 0.2s;
}

.letter {
  font-size: 23px;
  font-family: "Roboto";
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  line-height: 40px;
}

.reminder {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  line-height: 40px;
}

.profile {
  border-radius: 50%;
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
}

.bg-gray-900 {
  background-color: #262627 !important;
}
.bg-dark {
  background-color: #111827 !important;
}
.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;

  display: flex;
  height: 18px;
  width: 300px;
  bottom: -19rem;
}

.message-container {
  color: #fff;
  font-size: 14px;
}

.progress-value {
  animation: 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #0c9edc;
  height: 30px;
}
:root {
  /*========== Colors ==========*/
  --first-color: hsl(231, 44%, 56%);
  --first-color-alt: hsl(231, 44%, 51%);
  --text-color: hsl(231, 12%, 98%);
  --body-color: hsl(231, 100%, 94%);
}

.button__loader {
  border: none;
  width: 129px;
  outline: none;
  position: relative;
  padding: 11px 1.5rem;
  background-color: var(--first-color);
  color: var(--text-color);
  font-size: 12px;

  border-radius: 0.5rem;
  box-shadow: 0 18px 40px hsla(231, 56%, 56%, 0.3);
  overflow: hidden;
  cursor: wait;
  margin-top: 30px;
  margin-bottom: 26px;
}

.button__loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: repeating-linear-gradient(
    60deg,
    transparent,
    transparent 0.75rem,
    var(--first-color-alt) 0.75rem,
    var(--first-color-alt) 1.5rem
  );
  animation: load 1s infinite linear;
}

.button__text {
  position: relative;
}

/* Loading button animation */
@keyframes load {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1.75rem);
  }
}

.scan-button {
  width: 95px;
  height: 31px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  color: white;
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;
  margin-left: -9px;
  font-size: 10px;
}

.scan-button:hover {
  background-color: rgb(77, 77, 77);
  transition-duration: 0.2s;
}

.scan-button:active {
  transform: translate(1px, 1px);
  transition-duration: 0.2s;
}

.scan-con {
  position: relative;
}

.scan-con::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  border-left: 2px solid #fff;
}

.scan-mode {
  background: transparent;
  color: #fff;
  padding: 5px 10px;
  font-size: 9px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  transition: background-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-left: 87px;
  margin-bottom: 20px;
}

.scan-mode:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
}

.scan-mode:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.scan-mode:hover:before {
  transform: translateX(0);
}

/* Custom CSS for styling */
.card-header .logo {
  width: 22px;
  /* Adjust as needed */
  height: 22px;
  /* Adjust as needed */
  margin-right: 10px;
}

.card-header .label {
  font-size: 11px;
  line-height: 20px;
  margin-top: -3px;
  margin-left: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 156px;
  /* Align label vertically with logo */
}

.btn-width {
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 25px;
}

#containerflex {
  display: flex;
  align-items: center;
  max-width: 43%;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 10px;
}

#containerflex div {
  display: inline-block;
}

#containerflex h1 {
  margin: 0;
  font-size: 73px;
}

#fontslim {
  color: white;
  font-weight: 100;
  width: 100%;
}

#containerflex h1:first-child {
  margin-bottom: 5px;
  font-size: 21px;
}

#containerflex h1:last-child {
  font-size: 31px;
  text-align: left;
  margin-top: -6px;
  /* color: transparent;
    -webkit-text-stroke: 3px white; */
}

.custom-btn {
  background-color: gray;
  color: #fff;
  /* Set the background color to the same color as the default state */
}

.custom-btn:hover {
  border: 1px solid white;
  color: white;
  /* Ensure the background color remains the same on hover */
}

@media (max-width: 460px) {
  #containerflex h1 {
    font-size: 57px;
  }

  #containerflex h1:last-child {
    font-size: 90px;
    font-family: "gilroy-medium";

    -webkit-text-stroke: 3px white;
  }
}

@media (max-width: 400px) {
  #containerflex h1 {
    font-size: 47px;
  }

  #containerflex h1:last-child {
    font-size: 90px;
    font-family: "gilroy-medium";
    -webkit-text-stroke: 3px white;
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}

.border-none {
  border: none;
  border-color: transparent;
}

.impact {
  width: 100px;
  height: 50px;
  border-radius: 10px 10px 0 0;
}

.impact {
  width: 48px;
  height: 38px;
  border-radius: 10px 10px 0 0;
}

.urgency {
  width: 40px;
  color: white;
  text-align: center;
  border-radius: 0px 10px 10px 0;
}

.table-box {
  text-align: center;
  width: 155px;
  height: 89px;
}

.orange-border {
  border: 1px solid orange;
}

.custom-modal-dialog {
  max-width: 800px; /* Adjust the width as needed */
}

.dialog-box {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0 32px;
  max-width: 800px;
  width: 100%;
}
.heading {
  margin-top: -57px;
  position: absolute;
  font-size: 2px !important;
}
.grid-container {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  align-items: normal;
}
.excu-card {
  border-radius: 20px;
  border: 3px solid #58387b;
  background-color: #222c34;
  color: white;
  padding: 16px;
  height: auto;
}
.card-1,
.card-4,
.card-5 {
  height: auto !important;
}
.card-4,
.card-2 {
  width: 49%;
  flex: unset !important;
}
.card-3,
.card-5 {
  width: 49%;
  flex: unset !important;
}
.card-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.card-content p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
}
.financial-chart {
  width: 100%;
  height: 200px;
  background-color: #222c34;
  border-radius: 8px;
}
.financial-data-card,
.financial-conclusion-card {
  flex: 1;
}
.intro-reco-column {
  display: flex;
  /*flex-direction: column;*/
  justify-content: space-between;
}
.financial-conclusion-column {
  display: flex;
  /*flex-direction: column;*/
  justify-content: space-between;
}
.sub-card {
  border-radius: 20px;
  display: flex;
  gap: 15px;
  border: 1px solid #58387b;
  background-color: #313f4f;
  padding: 13px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#card1Content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: 7px;
}
.sub-card-content h4 {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
}
.sub-card-content p {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0;
  text-align: left;
}

@media only screen and (min-width: 300px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    margin-bottom: 0;
  }
  .card-1,
  .card-2,
  .card-3 {
    height: auto !important;
  }
}

@media only screen and (min-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
