/* style={{ marginTop: '-3rem', display: 'block', position: 'sticky', bottom: '0px', width: '100%', right: '0px', left: '0px' }} */

.searchbox {
  display: block;
  position: absolute;
  /* margin-top: -3rem; */
  bottom: 0;
  width: 95%;
  margin-bottom: 1.5rem;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

fieldset {
  border: none;
  border-radius: 25px;
  padding: 10px;
  background-color: #212428;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.loader {
  width: 20px !important;
  height: 20px !important;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 3px solid #6f8cda;
  border-top: 3px solid white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
