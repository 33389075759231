.view {
  font-family: "Noto Sans Mono", monospace;
  min-width: 75%;
  height: 100%;
  margin-left: 134px;
  
  border-radius: 20px;
  background-color: transparent;
 position: relative;
 
  ::-webkit-scrollbar-track {
    border: 0px solid black;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    @media (max-width: 480px) {
      width: 5px;
      height: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #212529;
  }
  &__tabControls {
    display: flex;
    height: 0px;
    margin-left: 56rem;
    background: #212529;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__tabControl {
    background: transparent;
    height: 100%;
    min-width: 140px;
    max-width: 200px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    color: white;
    @media (max-width: 480px) {
      width: calc(50% - 20px);
    }
    &__active {
      // background: #020d18;
      background: #0b2942;
      color: white;
      border-top: 1px solid gray;
      border-bottom: 1px solid #219fd5;
    }
    span {
      margin-left: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
