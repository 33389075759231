.handling-button-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 1rem 0rem 1rem 1rem;
  color: white;
  margin-right: 1.5rem;
}
.handling-buttons:hover {
  cursor: pointer !important;
}

.container-text {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .container-text p {
  font-size: 1rem;
  margin-top: 1rem;
} */

.playground {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 1.5rem;
  border: 1px solid #161616;
  background: #1b1b1b;
}

#chat-file {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2rem;
  margin-left: 2rem;
}

.chat-response {
  width: 80%;
  max-height: 90%;
  text-align: justify;
  margin-left: 2rem;
  overflow-y: auto;
  padding-right: 12px;
}

.chat-response::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  margin-left: 1rem;
}

.chat-response::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-response::-webkit-scrollbar-thumb {
  background: #888; /* color of the scrollbar */
  border-radius: 6px; /* roundness of the scrollbar */
}

.chat-response::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar on hover */
}

.pdf-container {
  display: flex;
  flex-direction: row;
}
.pdf-component {
  /* max-height: 50%; */
  position: fixed;
  top: -40px;
  right: 0;
  height: 75%;
  width: 20%;
  background-color: #252526;
}
