.register-main {
  display: flex;
}

.register-left {
  flex-grow: 0.3;
  height: 100vh;
  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.tagline {
  letter-spacing: 0.2rem;
  color: white;
  font-size: 0.8rem;
  margin: 0.5rem 1rem;
}
.header {
  margin: 0.5rem 1rem;
  max-width: 28rem;
  text-align: justify;
  font-size: 2rem;
  color: white;
}
.sub-header {
  margin: 0.5rem 1rem;
  font-size: 0.8rem;
  text-align: justify;
  max-width: 27rem;
  color: white;
}

.register-left img {
  width: 20rem;
}

.register-right {
  height: 100vh;

  flex-grow: 1;
}

.register-right-container {
  height: 100%;
  width: 80%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-right form {
  display: flex;
  flex-direction: column;
}

.register-logo {
  align-self: center;
  padding-top: 50px;
}

.register-center {
  margin: auto 0;
}

.register-logo img {
  width: 50px;
}

.register-center h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.register-center {
  text-align: center;
}

.register-center p {
  font-weight: 400;
  font-size: 1rem;
}

form input[type="email"],
form input[type="password"],
form input[type="text"] {
  width: 70%;
  padding: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  outline: none;
  box-sizing: border-box;
}

.Auth-Forms {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form button[type="submit"] {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: 600;
}

.register-center-options {
  display: flex;
  justify-content: space-between;
}

.remember-div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.remember-div label {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 2px;
}

.forgot-pass-link {
  text-decoration: none;
  font-size: 13px;
}

.forgot-pass-link:hover {
  text-decoration: underline;
}

.register-center-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  row-gap: 10px;
  width: 70%;
}

.button-1 {
  background: black;
  margin: 0.5rem 0;
  color: white;
  border: 3px solid black;
  transition: background 1s linear 0ms;
  transition: color 500ms linear 0ms;
}

.button-2 {
  background: #f0f0f0;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  transition: background 500ms linear 0ms;
}

.button-2 img {
  width: 30px;
}

.register-bottom-p {
  text-align: center;
  font-size: 15px;
  padding-bottom: 40px;
}

.register-bottom-p a {
  text-decoration: none;
  font-weight: 600;
}

.register-bottom-p a:hover {
  text-decoration: underline;
}

#terms-p {
  font-size: 0.7rem;
  margin-bottom: -0.5rem;
}

.center-buttons {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-buttons {
  width: 65%;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
}

.name-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.name-input input {
  width: 96% !important;
}

.name-container {
  display: flex;
  width: 70%;
}
