.progress-container {
  width: 18vw;
  /* margin: 10px; */
  text-align: center;
}

.progress-bar {
  height: 20px;
  background-color: #0c9edc;
  border-radius: 100px;
  margin-bottom: 10px;
}

.progress-info {
  font-size: 0.8rem;
  color: white;
  margin-bottom: 0;
}

.progress-info-meta {
  font-size: 0.8rem;
}
