.minimized-toolbar {
  color: white;
  background: #212428;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
}

.minimized-window-header {
  margin-right: 2.5rem;
}

.handling-buttons {
  margin: 0 0.4rem;
}
