.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 60%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  right: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.Right_Side {
  display: flex;
  justify-content: end;
}

.Right_Side .tooltip {
  margin-left: 10px;
}
.icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-top: 30px;
}
