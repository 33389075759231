.navbar{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #212529;
    //  #219fd54b;
    background: #212529;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

     .span{
        
     }
    &__right{
        
        margin-left: -.7rem;
        
        img{
            height: 180px;
        }
    }
    &__left{
        margin-top: .3rem;
        margin-right: 2rem;
        display: flex;
        align-items: center;
        @media (max-width: 480px){
            margin-right: 1rem;
        }
        & *{
            margin-left: .7rem;
            @media (max-width: 480px){
                margin-left: .4rem;
            }
            cursor: pointer;
            
            &:last-child{
               img{
                width: 21px;
                margin-bottom: .2rem;
               }
            }
        }
        img{
            width: 20px;
           
        }
    }
}