:root {
  --scrollbar-track-color: rgba(
    0,
    0,
    0,
    0.004
  ); /* Define the color of the scrollbar track */
  --scrollbar-thumb-color: #353a39; /* Define the color of the scrollbar thumb */
}

.layout {
 margin-top: 5rem;
  font-family: "Noto Sans Mono", monospace;
  max-width: 86vw;
  height: 100%;
  border-radius: 20px;
  margin-left: 0.5rem;
  margin-right: 8rem;
  overflow: hidden;
  margin-bottom: 2rem;

  &__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100vw;
    height: 105vh; 
    margin-right: 10rem;
  }
 
 
}

