.landing-container {
  background-color: black;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
}

.landing-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}

.logo {
  font-size: 80px;
  font-weight: 900; /* Extra bold */
  color: white;
  text-align: right;
  margin-right: 20px; /* Space between logo and icon */
  width: 100%;
  cursor: pointer;
}

.login-icon {
  width: 50px;
  height: 50px;
  fill: white;
  margin-left: 10px;
  cursor: pointer;
}
.subtitle {
  margin-left: 12.5rem;
}

.react-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  max-width: 40%;
  max-height: 50%;
  overflow: auto;
  transition: transform 0.3s ease;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-header {
  color: white;
  font-size: 1rem;
  background: none !important;
  /* display: block; */
  border-bottom: none !important;
}

.modal-container {
  display: flex;
  justify-content: space-between;
}

.modal-close {
  padding: 5px;
  background: none;
  color: white;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.modal-close:hover {
  cursor: pointer;
}

.video-content {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}
