.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  font-size: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-family: "Red Hat Display", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --background-color: #1a1616;
  --hover-color: #282828;
  --text-color: #d3d2d2;
  --border-color: #454545;
}

.ui-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  /* background-color: rgb(26, 22, 22); */
  /* background: #1b1b1b; */
  background: #161616;
}

.sidebar-container {
  width: min(320px, 33vw);
  height: 100vh;
  overflow-y: auto;
  padding: 12px 10px;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

.right-container {
  flex: 1;
  height: 100vh;
  overflow-y: hidden;
  padding: 1rem;
}

.sidebar-container::-webkit-scrollbar {
  width: 4px;
}

.sidebar-container::-webkit-scrollbar-thumb {
  background-color: var(--text-color);
  border-radius: 0.5rem;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #161616;
}

.create-tile {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #202020;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  gap: 8px;
}

.workspace-title {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #262626;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  gap: 8px;
  margin-top: 12px;
}

.sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto;
}

.sidebar-footer-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  height: 40px;
  background: #202020;
  flex: 1;
}

.create-tile .name,
.workspace-title .name {
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  color: var(--text-color);
  height: 100%;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  /* border: 1px solid var(--border-color); */
  font-size: 14px;
  color: white;
}

.create-tile .create,
.workspace-title .create {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  color: var(--text-color);
  padding: 1rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease-in-out;
  height: 100%;
}

.create-tile .name:hover,
.create-tile .create:hover,
.workspace-title .name:hover,
.workspace-title .create:hover {
  background-color: var(--hover-color);
}

.folder-chip {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  /* border-radius: 0.5rem; */
  /* background-color: rgb(26, 22, 22); */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* font-weight: 700; */
  /* gap: 1rem; */
  border-bottom: 1px solid transparent;
  padding-left: 16px;
  font-size: 14px;
  color: white;
}

.folder-chip-container.show .folder-chip {
  /* background-color: var(--hover-color); */
  border-bottom: 1px solid var(--border-color);
}

.folder-chip .name {
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: center;
  color: var(--text-color);
  height: 100%;
  padding: 1rem;
}

.folder-chip .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  padding: 1rem;
  gap: 1rem;
  height: 100%;
}

.folder-chip-items {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.folder-chip-container.show .folder-chip-items {
  margin-block: 5px;
}

.folder-chip-items .folder-chip-item {
  width: 100%;
  padding: 4px 20px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 1rem;
  color: white;
  position: relative;
  font-size: 14px;
}

.folder-chip-serial {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* border: 2px solid var(--border-color);
      background-color: #161616; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.folder-chip-serial-text {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
}

.folder-chip-serial::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  background-color: #161616;
}

.folder-chip-serial::after {
  content: "";
  width: 2px;
  height: calc(100% + 2px);
  background-color: var(--border-color);
  position: absolute;
  left: 30px;
  top: 0;
  z-index: -1;
}

/* .folder-chip-items .folder-chip-item::before {
      content: '';
      width: 2px;
      height: calc(100% + 2px);
      background-color: var(--border-color);
      position: absolute;
      left: 16px;
      top: 0;
    }
    
    .folder-chip-items .folder-chip-item::after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-bottom: 2px solid var(--border-color);
      position: absolute;
      left: 16px;
      top: 3px;
      transform: translateY(-50%) rotate(45deg);
      background-color: transparent;
    } */

.folder-chip-items .folder-chip-item.completed::after {
  border-bottom: 2px solid var(--text-color);
}

.folder-chip-items .folder-chip-item.completed::before {
  background-color: var(--text-color);
}

.folder-chip-items .folder-chip-item:first-child::before {
  top: 4px;
  height: calc(100% + 2px - 4px);
}

.folder-chip-items .folder-chip-item:last-child::before {
  height: 3px;
}

.folder-chip-items .folder-chip-item:hover {
  background-color: var(--hover-color);
}

.chip-indicator-icon {
  transition: all 0.2s ease-in-out;
}

.folder-chip-container {
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.folder-chip-container.show .chip-indicator-icon {
  transform: rotate(-180deg);
}

.folder-chip-container:not(.show) .folder-chip-items {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.folder-chip-container.show .folder-chip-items {
  max-height: 1000px;
  overflow: visible;
}

.project-edit-icon {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.folder-chip:hover .project-edit-icon {
  opacity: 1;
}

.workspace-container .folders-container {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.folders-container.show {
  max-height: 1000px;
  overflow: visible;
}

.workspace-indicator-icon {
  transition: all 0.2s ease-in-out;
}

.workspace-title.show .workspace-indicator-icon {
  transform: rotate(-180deg);
}

.workspace-actions {
  margin-left: auto;
  display: flex;
  gap: 8px;
}

.workspace-edit-icon {
  margin-left: auto;
}

.inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 1.5rem;
  border: 1px solid #161616;
  background: #1b1b1b;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.main-container {
  width: min(100%, 55rem);
}

.insert-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-top: 3rem;
  padding: 1rem;
  border-radius: 0.75rem;
  min-height: 9rem;
}

.insert-container .tiles {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 0.5rem; */
  border-radius: 0.75rem;
  border: 1px solid #323232;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  max-height: 52px;
}

.insert-container .tiles:hover {
  background: #282828;
}

.insert-container .tiles .tiles-description {
  color: #a4a4a4;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
}

.workspace-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  margin-inline: -5px;
  padding-inline: 5px;
}

.deployh-logo {
  padding: 0px 10px 10px;
}

.deployh-first {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.deployh-second {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.inputfile {
  visibility: hidden;
  width: 0;
  height: 0;
}
.tooltip {
  display: flex;
  background: black;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  display: none;
  position: absolute;
  z-index: 999;
  white-space: nowrap;
}

.folder-chip-item {
  position: relative;
  display: inline-block;
}

.folder-chip-item:hover .tooltip {
  display: block;
  left: 55%;
}
.forminput {
  border: 2px solid #373737;
  width: 25vh;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  color: aliceblue;
  padding: 5px;
  line-height: 11px;
}

.info-box {
  background-color: #111827;
  padding: 1.5rem;
  border-radius: 0.25rem;
  text-align: center;
  width: 85%;
  height: 35%;
  margin-left: 20px;
  margin-bottom: 5px;
}
.info-image {
  width: 40%;
  max-width: 95%;
}

.text-secondary {
  color: #6b7280;
  margin-bottom: 0.5rem !important;
}

.deployh-sidebar-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#close-sidebar {
  color: white;
  margin-right: -0.5rem;
}

#close-sidebar:hover {
  cursor: pointer;
}

.toggle-sidebar {
  color: white;
  margin: 1rem -0.5rem 0rem 1rem;
}

.toggle-sidebar:hover {
  cursor: pointer;
}

.side-bar-img {
  padding: 3px;
  text-align: center;
  margin-bottom: 1rem;
  /* height: 13rem; */
  background-color: #111827 !important;
  border-radius: 3px;
}
