.outer-box {
  background-color: #1b1d1f;
  border: 2px solid #000;
  width: fit-content;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .heading {
  color: #f0ecec;
  margin: 30px 30px 0 20px;
  font-size: 25px !important;
  position: relative;
} */

/* .line {
    width: calc(24% - 50px); 
    height: 5px; 
    background: linear-gradient(to right, #007bff, #00ff00, #ff0000); 
    position: absolute;
    top: calc(12% - 10px); 
    left: 3%;
} */

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 20vw;
  height: 50vh;
  margin: 20px;
  background-color: #2a2e32;
  color: #f0ecec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.card:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.card-subheading {
  font-size: small;
}

.card-title {
  margin: 0;
  font-size: 25px;
  text-align: center;
}

.card-line {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.card-points {
  list-style: none;
  padding: 0;
}

.card-points li {
  margin-left: 10px;
  position: relative;
  font-size: 0.8rem;
}

.card-points li::before {
  content: "\✔";
  position: absolute;
  left: -20px;
}

.card-button {
  margin-top: auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.card-button:hover {
  background-color: #0056b3;
}
